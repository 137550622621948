import React, { useCallback, useRef } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

import * as css from "./about.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"

import metaPreviewImage from "../assets/images/thumbnails/thumbnail_index.jpg"
import MetaTags from "../components/MetaTags"

// markup
const About = () => {
    return (
        <>
            <MetaTags
                metaDescription="Tentang Aplikasi"
                metaTitle="Tentang Aplikasi"
                metaPath="/about"
                metaPreviewImage={metaPreviewImage}
            />
            <Header />

            <Container className={css.section}>
                <Row>
                    <Col>
                        <h1>Tentang Aplikasi</h1>

                        <p>
                            Aplikasi ini dibuat oleh <b>Khakim Hudaya</b> (<a href="https://twitter.com/huedaya" target="_blank">@huedaya <FontAwesomeIcon icon={faTwitter} /></a>).<br />

                            <br />
                            Aplikasi <b>KTP Anabul</b> (<a href="https://twitter.com/KtpAnabul" target="_blank">@KtpAnabul <FontAwesomeIcon icon={faTwitter} /></a>) juga masuk kedalam produk dari pengembang perangkat lunak <b>Melon Kotak</b> (<a href="https://twitter.com/melonkotak" target="_blank">@melonkotak <FontAwesomeIcon icon={faTwitter} /></a>).
                            <br />
                            <br />
                            Jika ada kritik, saran, pertanyaan, atau permintaan fitur menghubungi langsung melalui Twitter ya. Trims!

                            <br />
                            <br />
                            <br />
                        </p>

                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default About
