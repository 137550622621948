import * as React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"
import * as styles from "./Footer.module.css"
import KtpAnabulLogo from "../assets/images/ktp_anabul_logo_dark_mode.svg"
import { Col, Row } from "react-bootstrap"

const Footer = () => {
  return (
    <>
      <footer className="text-center text-lg-start bg-light text-muted footer-section">
        <section className={styles.section}>
          <div className="container text-center text-md-start pt-5">
            <Row>
              <Col className={styles.links}>
                <div className={styles.link}>
                  <Link to={"/mission"}>Misi</Link>
                </div>
                <div className={styles.link}>
                  <Link to={"/features"}>Fitur</Link>
                </div>
                {/* <div className={styles.link}>
                  <Link to={"/donation-jar"}>Donation Jar</Link>
                </div> */}
                <div className={styles.link}>
                  <Link to={"/tos"}>Kebijakan Layanan</Link>
                </div>
                <div className={styles.link}>
                  <Link to={"/privacy"}>Privasi</Link>
                </div>
                <div className={styles.link}>
                  <a href={"https://stats.uptimerobot.com/YNX55tD2Kg"} target="_blank">Status Layanan</a>
                </div>
                {/* Hide because not useful for now */}
                {/* <div className={styles.link}>
                  <Link to={"/press-kit"}>Press Kit</Link>
                </div> */}
                <div className={styles.link}>
                  <Link to={"/about"}>Tentang Aplikasi</Link>
                </div>
              </Col>
            </Row>

            <Row className={styles.social}>
              <Col>
                <div className={styles.bottomLogo}>
                  <object type="image/svg+xml" data={KtpAnabulLogo}>
                    KTP Anabul
                  </object>
                </div>

                <div className={styles.socialMedia}>
                  <a href="https://twitter.com/ktpanabul" target="_blank">
                    <FontAwesomeIcon icon={faTwitter} /> @<span>KTP</span>anabul
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <div className={styles.copy}> &copy; {new Date().getFullYear()} Melon Kotak Creative</div>
      </footer>
    </>
  )
}

export default Footer
