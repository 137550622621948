import * as React from "react"
import log from "../log"
import { Row, Col, Button, Form, Modal } from "react-bootstrap"
import Steps from "rc-steps"
import "rc-steps/assets/index.css"
import * as css from "./ModalHelpBox.module.css"
import catImage from "../../assets/images/cat.jpg"
import dogImage from "../../assets/images/dog.jpg"
import sexMale from "../../assets/images/sex_male.svg"
import sexFemale from "../../assets/images/sex_female.svg"
import ReactTooltip from "react-tooltip"
import { navigate } from "gatsby"
import { faCircleNotch, faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useAuth from "../../hooks/useAuth"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"

import { useLocation } from "@gatsbyjs/reach-router"

const ModalHelpBox = () => {
    const [isVisible, setIsVisible] = React.useState(false)
    const [currentPath, setCurrentPath] = React.useState('')
    const [faqModalContent, setFaqModalContent] = React.useState([])
    const [localHelpModalContent, setLocalHelpModalContent] = React.useState([])
    const location = useLocation();

    React.useEffect(() => {
        fetch('/json/faq_modal.json')
            .then((response) => response.json())
            .then((data) => {
                setFaqModalContent(data)
            });
        fetch('/json/local_help_modal.json')
            .then((response) => response.json())
            .then((data) => setLocalHelpModalContent(data));
    }, [])


    React.useEffect(() => {
        let path = location.pathname;
        if (path.length == 1) {
            setCurrentPath('/')
        } else {
            path = path.replace(/\/+$/g, '');
            setCurrentPath(path);
        }
    }, [location])

    return (
        <>
            <div className={css.helpBox} onClick={() => setIsVisible(!isVisible)}>
                <div className={css.emoji}>🙋</div>
                Bantuan
            </div>
            <Modal
                show={isVisible}
                className="modal fade modal-help-box"
                size="lg"
                onHide={() => {
                    setIsVisible(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>🙋 Bantuan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={css.window}>
                        {
                            localHelpModalContent.map((item: any, index) => {
                                if (item.path == currentPath) {
                                    return (
                                        <div className={css.localContent} key={index}>
                                            <Row className={css.helpItem}>
                                                <div className={css.video}>
                                                    <iframe
                                                        width="100%"
                                                        height="auto"
                                                        src={item.video}
                                                        frameBorder={0}
                                                        title="YouTube video player"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen={true}
                                                    ></iframe>
                                                </div>
                                                <Col>
                                                    <div className={css.title}>{item.title}</div>
                                                    <div className={css.summary}>
                                                        {item.summary}
                                                    </div>
                                                </Col>

                                            </Row>
                                            <div className={css.anotherQuestion}>Pertanyaan Lain</div>
                                        </div>
                                    )
                                }

                            })
                        }


                        <div className={css.content}>
                            {
                                faqModalContent.map((item: any, index) => {
                                    return (
                                        <a href={item.link} target="_blank" key={index}>
                                            <Row className={css.helpItem}>
                                                <Col>
                                                    <div className={css.title}>{item.title}</div>
                                                    <div className={css.summary}>
                                                        {item.summary}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </a>
                                    )
                                })
                            }

                        </div>
                        <a href="https://t.me/huedaya" className={css.bottomBar} target="_blank">
                            <div className={css.twitterAva}>
                                <img src="https://pbs.twimg.com/profile_images/1459265110942224388/Rkhe90F6_400x400.jpg" alt="@huedaya" />
                            </div>
                            <div className={css.name}>Jika masih mengalami kesulitan, klik disini untuk hubungi @huedaya di Telegram</div>
                        </a>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalHelpBox
