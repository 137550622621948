import * as React from "react"
import { Link, navigate } from "gatsby"
import "../assets/styles/global.css"
import { Navbar, Nav, Container, Dropdown, DropdownButton } from "react-bootstrap"

import * as styles from "./Header.module.css"
import logoLightBasic from "../assets/images/ktp_anabul_logo_basic.png"
import logoLightPro from "../assets/images/ktp_anabul_logo_pro.png"
import useAuth from "../hooks/useAuth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCartShopping, faCreditCard, faDiamond, faDoorOpen, faGem, faStar, faThumbsUp, faWrench } from "@fortawesome/free-solid-svg-icons"
import useIsInPro from "../hooks/useIsInPro"
import ModalHelpBox from "./help/ModalHelpBox"

const Header = () => {
  const { isAuthenticated, user } = useAuth()
  const isInProCheck = useIsInPro();
  const [isInPro, setIsInPro] = React.useState(false)
  const [isNavMobileOpen, setIsNavMobileOpen] = React.useState(false)

  /**
   * Change home target if user already authenticated
   */
  let homeTarget = "/"
  if (isAuthenticated) {
    homeTarget = "/dashboard"
  }

  React.useEffect(() => {
    const getStatus = async () => {
      setIsInPro(await isInProCheck())
    }
    getStatus();
  })

  return (
    <>
      <Navbar expand="lg" className={styles.navbar}>
        <Container>
          <Link to={homeTarget}>
            <Navbar.Brand className={styles.navbarBrand}>
              {
                isInPro ? (
                  <img src={logoLightPro} alt="Logo" />
                ) : (
                  <img src={logoLightBasic} alt="Logo" />
                )
              }
            </Navbar.Brand>
          </Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>
              <div className={'nav-link'}>
                <Link to="/features">Fitur</Link>
              </div>
              <div className={'nav-link'}>
                <Link to="/mission">Misi</Link>
              </div>
              {isAuthenticated ? (
                <>
                  <DropdownButton
                    id="dropdown-basic-button"
                    variant="default"
                    className={styles.dropdown}
                    title={
                      <>
                        {
                          user != null && user.avatar !== null && user.firstName !== null ? (
                            <>
                              {user.firstName}

                              <div className={styles.avatar}>
                                <img src={user.avatar} />
                              </div></>
                          ) : (
                            <>
                              {
                                user != null && user.id !== null ? (
                                  <>
                                    {user.id}
                                  </>
                                ) : (
                                  <>
                                    Tanpa Nama
                                  </>
                                )
                              }
                              <div className={styles.avatar}>
                                <img src={'https://bucket-auth.melonkotak.com/blank.jpg'} />
                              </div>
                            </>
                          )
                        }

                      </>
                    }
                  >
                    <Dropdown.Item onClick={() => navigate("/dashboard/checkout-history")}>
                      <FontAwesomeIcon icon={faCartShopping} /> Riwayat Pembelian
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/dashboard/physical-card")}>
                      <FontAwesomeIcon icon={faCreditCard} /> Beli Kartu Fisik
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/dashboard/subscription")}>
                      <FontAwesomeIcon icon={faGem} /> Langganan
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/dashboard/settings")}>
                      <FontAwesomeIcon icon={faWrench} /> Pengaturan Akun
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/logout")
                      }}
                    >
                      <FontAwesomeIcon icon={faDoorOpen} /> Keluar
                    </Dropdown.Item>
                  </DropdownButton>
                </>
              ) : (
                <>
                  {/* <Nav.Link href="/bantuan">
                    <Link to="/bantuan">Bantuan</Link>
                  </Nav.Link> */}
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar >
      <Navbar expand="lg" className={styles.navbarMobile}>
        <Container>
          <Link to={homeTarget}>
            <Navbar.Brand className={styles.navbarBrand}>
              {
                isInPro ? (
                  <img src={logoLightPro} alt="Logo" />
                ) : (
                  <img src={logoLightBasic} alt="Logo" />
                )
              }
            </Navbar.Brand>
          </Link>

          <div className={styles.avatarContainer} onClick={() => setIsNavMobileOpen(!isNavMobileOpen)}>
            {
              user != null && user.avatar !== null && user.firstName !== null ? (
                <>
                  {user.firstName}

                  <div className={styles.avatar}>
                    <img src={user.avatar} />
                  </div></>
              ) : (
                <>
                  {
                    user != null && user.id !== null ? (
                      <>
                        {user.id}
                      </>
                    ) : (
                      <>
                        Tanpa Nama
                      </>
                    )
                  }
                  <div className={styles.avatar}>
                    <img src={'https://bucket-auth.melonkotak.com/blank.jpg'} />
                  </div>
                </>
              )
            }
          </div>
          <Navbar.Collapse id="basic-navbar-nav" className={isNavMobileOpen ? 'show' : ''}>
            <Nav>
              <Link to="/features" onClick={() => setIsNavMobileOpen(false)}> <FontAwesomeIcon icon={faGem} /> Fitur</Link>
              <Link to="/mission" onClick={() => setIsNavMobileOpen(false)}> <FontAwesomeIcon icon={faStar} /> Misi</Link>
              {isAuthenticated ? (
                <>
                  <Link to="/dashboard/physical-card" onClick={() => setIsNavMobileOpen(false)}><FontAwesomeIcon icon={faCreditCard} />  Beli Kartu Fisik</Link>
                  <Link to="/dashboard/checkout-history" onClick={() => setIsNavMobileOpen(false)}><FontAwesomeIcon icon={faCartShopping} />  Riwayat Pembelian</Link>
                  <Link to="/dashboard/subscription" onClick={() => setIsNavMobileOpen(false)}><FontAwesomeIcon icon={faGem} />  Langganan</Link>
                  <Link to="/dashboard/settings" onClick={() => setIsNavMobileOpen(false)}><FontAwesomeIcon icon={faWrench} />  Pengaturan Akun</Link>
                  <Link to="/logout" onClick={() => setIsNavMobileOpen(false)}><FontAwesomeIcon icon={faDoorOpen} />  Keluar</Link>
                </>
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar >

      <ModalHelpBox />
    </>
  )
}

export default Header
