import React from "react";
import log from "../components/log";
import useAuth from "./useAuth";
import useAxiosPrivate from "./useAxiosPrivate"

const useIsInPro = () => {
    const isDebug = (process.env.APP_DEBUG == 'true')
    const axiosPrivate = useAxiosPrivate();
    const { isAuthenticated } = useAuth()
    const [lastTimeDoCheck, setLastTimeDoCheck] = React.useState(getLastCheckLocalStorage())

    const check = async () => {
        // If token not exist then no need to check subscription status
        if (!isAuthenticated) {
            return false
        }

        // Stop if user already checking
        let diff = ((new Date).getTime() - lastTimeDoCheck) / 1000;
        if (diff < 1 * 60) { // recheck in 1 min
            if (typeof window !== 'undefined') {
                let isInPro = localStorage.getItem('is_in_pro')
                if (isInPro != undefined) {
                    return (isInPro == 'true');
                }
            }
        }

        // Call the API
        const res = await axiosPrivate.get('/api/auth/is_in_pro')
        if (res != undefined) {
            if (typeof window !== 'undefined') {
                localStorage.setItem('is_in_pro', res.data.data.is_in_pro)
                localStorage.setItem('last_check_is_in_pro', (new Date).getTime().toString())
            }
            setLastTimeDoCheck((new Date).getTime())
        }

        log('debug', 'Getting subscription status', {
            isInPro: res.data.data.is_in_pro
        })
        return res.data.data.is_in_pro
    }
    return check
}

function getLastCheckLocalStorage() {
    if (typeof window !== "undefined") {
        const timestamp = localStorage.getItem("last_check_is_in_pro")
        if (timestamp != null) {
            return Number(timestamp)
        }
    }

    var yesterday = new Date((new Date).getTime());
    yesterday.setDate((new Date).getDate() - 1);
    return yesterday.getTime()
}

export default useIsInPro
