// extracted by mini-css-extract-plugin
export var anotherQuestion = "ModalHelpBox-module--anotherQuestion--cfbc4";
export var bottomBar = "ModalHelpBox-module--bottomBar--0e86e";
export var close = "ModalHelpBox-module--close--93a82";
export var content = "ModalHelpBox-module--content--6d077";
export var emoji = "ModalHelpBox-module--emoji--14e35";
export var helpBox = "ModalHelpBox-module--helpBox--0a636";
export var helpItem = "ModalHelpBox-module--helpItem--15bef";
export var localContent = "ModalHelpBox-module--localContent--81eab";
export var name = "ModalHelpBox-module--name--43401";
export var summary = "ModalHelpBox-module--summary--c5ff8";
export var topBar = "ModalHelpBox-module--topBar--a956f";
export var twitterAva = "ModalHelpBox-module--twitterAva--27253";
export var video = "ModalHelpBox-module--video--90a37";